<template>
    <div>
        <section id="References" class="section section-4 light-background">
            <div class="container">
                <div class="header-container">
                    <h2>{{ $t('references') }}</h2>
                </div>
                <div class="row">
                    <VueSlickCarousel  v-bind="settings" class="col-12 customer-logos slider" v-if="refItems.length">
                        <div class="slide" v-for="refItem of refItems" v-bind:key="refItem._id">
                            <a :href="refItem.link" target="_blank">
                                <img :src="'https://cdn.tavguvenlik.com/uploads/5e5d10f0059b14822c6f256e/static-pages/list-images/' + refItem.listImage" :alt="refItem.title">
                            </a>
                        </div>
                    </VueSlickCarousel>                       
                </div>
            </div>
        </section>
    </div>
</template>

<script>
    import ContentService from '@/services/ContentService.js';
    import VueSlickCarousel from 'vue-slick-carousel'
    // optional style for arrows & dots
    import 'vue-slick-carousel/dist/vue-slick-carousel-theme.css'

            
    export default {
        data() {
        return {
            refItems: [],
        settings: {
          slidesToShow: 4,
            slidesToScroll: 1,
            autoplay: true,
            autoplaySpeed: 3000,
            arrows: false,
            dots: false,
            pauseOnHover: false,
            responsive: [{
                breakpoint: 768,
                settings: {
                    slidesToShow: 4
                }
            }, {
                 breakpoint: 520,
                settings: {
                    slidesToShow: 3
                }
            }]
        },
      }
    },
     created () {
        ContentService.getReferences(this.$application)
            .then(res => this.refItems = res.data.data)
            .catch(error => console.log('There was an error:' + error))   
    },
    components: {
    VueSlickCarousel
    }
  }
  
</script>

<style>
</style>