<template>
    <div>
    <NavBar></NavBar>
    <Breadcrumb></Breadcrumb>
    <div class="container">
      <div class="row mt-3">
        <div class="col-lg-3">
          <SideBar></SideBar>
        </div>
        <div class="col-lg-9 content">
          <div class="pages"><div>     
            <section id="Movie">
                <div class="container">
                    <h1 class="main-title">{{ $t("movie") }}</h1>
                   <gallery :images="images" :index="index" 
                   :options="{youTubeVideoIdProperty: 'youtube', youTubePlayerVars: undefined, youTubeClickToPlay: true}" 
                   @close="index = null"></gallery>
                    
                    <div class="image" v-for="(image, imageIndex) in images" :key="imageIndex" @click="index = imageIndex" 
                    :style="{ backgroundImage: 'url(' + image.poster + ')', width: '300px', height: '200px' }"></div>
                </div>
            </section>
          </div>
        </div>
      </div>
    </div>
    
  </div>
  <Footer></Footer>
  </div>
</template>


<script>
import VueGallery from 'vue-gallery';

import NavBar from "@/components/shared/NavBar.vue";
import Breadcrumb from "@/components/shared/Breadcrumb.vue";
import SideBar from "@/components/shared/SideBar.vue";
import Footer from "@/components/shared/Footer.vue";

export default {
  data() {
    return {
      pageSlug: this.$route.params.slug,
      images: [
          {
          title: 'TAV Security Video',
          href: 'https://cdn.tavguvenlik.com/files/1592383207_tav_10yil.mp4',
          //type: 'text/html',
          type: 'video/mp4',
          youtube: 'hNdlUHBJDKs',
          poster: 'https://cdn.tavguvenlik.com/files/1592383877_tavsw1.jpg'
        },
        {
          title: 'TAV Security Video',
          href: 'https://cdn.tavguvenlik.com/files/1592383303_sivi_master.mp4',
          type: 'video/mp4',
          poster: 'https://cdn.tavguvenlik.com/files/1592383930_tavsw2.jpg'
        },
        {
          title: 'TAV Security Video',
          href: 'https://cdn.tavguvenlik.com/files/1592381938_tp.mp4',
          type: 'video/mp4',
          poster: 'https://cdn.tavguvenlik.com/files/1592383952_tavsw3.jpg'
        }
      ],
      index: null
    };
  },
   components: {
    'gallery': VueGallery,
    NavBar,
    Breadcrumb,
    SideBar,
    Footer
  },
   props: {
    slug: {
      type: String,
      required: true,
    }
  },
  created() {
   
  },
  computed: {
   
  },
};


</script>

<style scoped>
.image {
    float: left;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center center;
    border: 1px solid #ebebeb;
    margin: 5px;
  }
  a:not([href]):not([tabindex]) {
    color: white !important;
    text-decoration: none;
}
</style>
