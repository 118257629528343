<template>
    <div id="TrainingServices" class="col-lg-4">
        <div class="section"> 
            <span v-for="navItem in navItems" v-bind:key="navItem._id">
            <div v-if="navItem.subDynamicFields && navItem.subDynamicFields.length">
                <span v-for="subNavItem in navItem.subDynamicFields" v-bind:key="subNavItem._id">
                    <span v-if="subNavItem.slug == title">
                        <h2>{{ subNavItem.value }}</h2>
                        <div class="image-container">
                            <img :src="'https://cdn.tavguvenlik.com/uploads/5e5d10f0059b14822c6f256e/dynamic-fields/images/' + subNavItem.activeImage" alt="" width="100%">
                        </div>
                        <div class="nav-item" v-for="subSubNavItem in subNavItem.subDynamicFields" v-bind:key="subSubNavItem._id">
                            <a :href="subSubNavItem.link" class="d-flex justify-content-between align-items-center">
                                <span>{{ subSubNavItem.value }}</span>
                                <img src="./../assets/img/arrow-icon.svg" alt="Arrow icon" width="7" height="12">
                            </a>
                        </div>
                     </span>
                </span>
            </div>
            </span>
        </div>
    </div>
</template>

<script>
export default {
data () {
    return {
        navItems: [],
        title: this.$t("trainingServicesSlug")
        
    };
},
created () {
    return this.$apiClient
      .get('/dynamicFields?key=' + 'category' + '&application=' + this.$application + '&language=' + this.$t("languageCode"))
      .then(res => this.navItems =res.data.data.subDynamicFields)
      .catch(error => console.log('There was an error:' + error))  
        
    }
}
</script>

<style>
</style>