<template>
    <div>

        <section id="MainpageSlider">
            <VueSlickCarousel v-bind="settings" class="Modern-Slider" v-if="sliderItems.length">

                <!-- Item -->

                <div class="item" v-for="sliderItem of sliderItems" v-bind:key="sliderItem._id">

                    <div class="img-fill">
                        <img :src="'https://cdn.tavguvenlik.com/uploads/5e5d10f0059b14822c6f256e/sliders/' + sliderItem.fileName"
                            :alt="sliderItem.title" width="100%">
                        <div class="info">
                            
                                <div>
                                    <a :href="sliderItem.link"><div :inner-html.prop="sliderItem.subDescription"></div></a>
                                    <h3><a :href="sliderItem.link">{{ sliderItem.title }}</a></h3>
                                </div>
                            
                        </div>
                    </div>

                </div>

                <!-- // Item -->

            </VueSlickCarousel>
        </section>
    </div>
</template>

<script>

import ContentService from '@/services/ContentService.js';
import VueSlickCarousel from 'vue-slick-carousel'
// optional style for arrows & dots
import 'vue-slick-carousel/dist/vue-slick-carousel-theme.css'

export default {
    data() {
        return {
            sliderItems: [],
            settings: {
                autoplay: true,
                autoplaySpeed: 10000,
                speed: 600,
                slidesToShow: 1,
                slidesToScroll: 1,
                pauseOnHover: false,
                dots: true,
                pauseOnDotsHover: true,
                cssEase: 'linear',
                // fade:true,
                draggable: true,
                arrows: true,
                prevArrow: "<img class='a-left control-c prev slick-prev' src='@/assets/img/arrow-icon.svg'>",
                nextArrow: "<img class='a-right control-c next slick-next' src='@/assets/img/arrow-icon.svg'>",
                infinite: true
            },
        }
    },
    created() {
        ContentService.getMainSlider(this.$application, this.$t("languageCode"))
            .then(res => this.sliderItems = res.data.data)
            .catch(error => console.log('There was an error:' + error))
    },
    // All slick methods can be used too, example here
    methods: {
        next() {
            this.$refs.slick.next();
        },

        prev() {
            this.$refs.slick.prev();
        },
        reInit() {
            // Helpful if you have to deal with v-for to update dynamic lists
            this.$nextTick(() => {
                this.$refs.slick.reSlick();
            });
        },
        onInitCarousel() {
            console.log('our carousel is ready')
        }
    },
    components: {
        VueSlickCarousel
    }
}

</script>

<style>
</style>